import * as Klaro from 'klaro/dist/klaro-no-css';

const config = {
  acceptAll: true,
  translations: {
    de: {
      privacyPolicyUrl: '/datenschutz',
      consentNotice: {
        description: 'Hallo! Könnten wir bitte zusätzliche Dienste für {purposes} aktivieren? Sie können Ihre Zustimmung später jederzeit ändern oder zurückziehen. Um mehr zu erfahren, lesen Sie bitte unsere {privacyPolicy}.',
        learnMore: 'Einstellungen',
      },
      decline: 'Ablehnen',
      ok: 'Akzeptieren',
      consentModal: {
        title: 'Einstellungen zu Diensten und Cookies',
        description: 'Wir verwenden Cookies und Drittanbieterdienste, um Ihnen ein möglichst optimales und auf Ihre Bedürfnisse zugeschnittenes ​Erlebnis unseres Onlineangebots zu bieten. Dazu zählen Cookies, die für den reibungslosen und sicheren Betrieb des Onlineangebots notwendig sind, sowie solche die zu statistischen Zwecken verwendet werden um das Onlineangebot für Sie zu optimieren. '
          + 'Sie können selbst entscheiden, welche Dienste Sie erlauben möchten.',
      },
      purposes: {
        analytics: {
          title: 'Besucherstatistik',
          description: 'Diese Dienste verarbeiten personenbezogene Daten, um den von dieser Website angebotenen Service zu optimieren.',
        },
        video: {
          title: 'Video',
        },
        functional: {
          title: 'Funktionalität',
          description: 'Dienste die für den Betrieb unserer Website zwingend erforderlich sind.',
        },
      },
    },
  },
  services: [{
    name: 'google-analytics',
    purposes: ['analytics'],
    default: false,
    required: false,
    translations: {
      de: {
        title: 'Google Analytics',
        description: 'Google Analytics ist ein Tool, mit dem wir messen können, wie Benutzer mit Website - Inhalten interagieren.Mit diesen Informationen optimieren wir unseren Webauftritt für Sie.Verarbeitung Ihrer Nutzerdaten durch Google.',
        cookieDetailsUrl: 'https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage',
        privacyPolicyUrl: 'https://policies.google.com/privacy?hl={lang}',
      },
    },
    cookies: [{
      pattern: '^_ga$',
      expiresAfter: '2 years',
      path: '/',
      _id: '_8tkk7rhnc',
    },
    {
      pattern: '^_gid$',
      expiresAfter: '24 hours',
      path: '',
      _id: '_50tc32o8v',
    },
    {
      pattern: '^_gat$',
      expiresAfter: '1 minute',
      path: '',
      _id: '_ma213a9s2',
    },
    {
      pattern: '^AMP_TOKEN$',
      expiresAfter: '1 year',
      path: '',
      _id: '_y5vh053f3',
    },
    {
      pattern: '^_gac_',
      expiresAfter: '90 days',
      path: '',
      _id: '_1u6lf1t6k',
    },
    {
      pattern: '^__utmz$',
      expiresAfter: '6 months',
      path: '',
      _id: '_zjcc95k83',
    },
    {
      pattern: '^__utma$',
      expiresAfter: 'never',
      path: '',
      _id: '_dkeo8e86l',
    },
    {
      pattern: '^__utmb$',
      expiresAfter: '30 minutes',
      path: '',
      _id: '_yoewq47mg',
    },
    {
      pattern: '^__utmv$',
      expiresAfter: 'never',
      path: '',
      _id: '_7uczuzukw',
    },
    {
      pattern: '^__utmt$',
      expiresAfter: '10 minutes',
      path: '',
      _id: '_jtab3aqih',
    },
    {
      pattern: '^__Secure$',
      expiresAfter: '',
      path: '.google.com',
      _id: '_lil7grc74',
    },
    ],
    localStorage: [],
    sessionStorage: [],
    elements: null,
    requests: [{
      url: 'https://www\\.google-analytics\\.com',
    }],
  },
  {
    name: 'youtube',
    purposes: ['video'],
    translations: {
      de: {
        title: 'YouTube',
        description: 'YouTube kann an manchen Stellen eingebettet sein um Videos anzuzeigen.',
        privacyPolicyUrl: 'https://www.youtube.com/t/privacy',
      },
    },
  },
  {
    name: 'vimeo',
    purposes: ['video'],
    translations: {
      de: {
        title: 'Vimeo',
        description: 'Vimeo kann an manchen Stellen eingebettet sein um Videos anzuzeigen.',
        cookieDetailsUrl: 'https://vimeo.com/cookie_policy',
        privacyPolicyUrl: 'https://vimeo.com/privacy',
      },
    },
  },
  {
    name: 'webfonts',
    purposes: ['functional'],
    default: true,
    required: true,
    optOut: false,
    contextualConsentOnly: false,
    onlyOnce: false,
    translations: {
      zz: {
        title: 'Webfonts',
      },
      de: {
        description: 'Nutzung von Web Fonts die von fast​.fonts​.net bereitgestellt werden zur einheitlichen Darstellung von Schriftarten.',
      },
    },
  },
  {
    name: 'klaro',
    purposes: ['functional'],
    default: true,
    required: true,
    optOut: false,
    contextualConsentOnly: false,
    onlyOnce: false,
    translations: {
      zz: {
        title: 'Consent-Einstellungen',
      },
      de: {
        description: 'Speicherung der in diesem Consent-Banner getroffenen Einstellungen.',
      },
    },
  },


    // {
    //   name: 'vimeo',
    //   purposes: ['video'],
    // },


  ],
};

// we assign the Klaro module to the window, so that we can access it in JS
window.klaro = Klaro;
// window.klaroConfig = config;
// we set up Klaro with the config
Klaro.setup(config);
